<template>
  <div>
    <b-row>
      <b-col md="12">
        <export-button :filter="exportFilter"
                       url="/transaction/export-report-rewards"
                       :disabled="loading.overlay ||  loading.search || !data.rewards.length"
                       file-title="Big_prize_Report.xlsx"/>
      </b-col>
      <b-col md="12" class="mt-2">
        <b-card no-body class="p-1">
          <b-card-title class="mb-2">
            {{ $t("tabs.reportBigPrizes") }}
          </b-card-title>


          <b-row>

            <b-col>
              <b-form-group
                  label="init Date"
                  label-for="initDate"
              >
                <template #label>{{ $t('labels.datefrom') }}</template>
                <flat-pickr
                    v-model="initDate"
                    class="form-control"
                    :config="{
                    altInput: true,
                    clearable: true,
                    altFormat: 'd-m-Y H:i',
                    enableTime: true,
                    time_24hr: true,
                    wrap : true,
                    plugins: [
                       new yearDropdownPlugin({
                         yearStart: 100,
                         yearEnd: 0
                      })
                     ],
                  }"
                />

              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  label="end Date"
                  label-for="endDate">
                <template #label>{{ $t('labels.dateto') }}</template>

                <flat-pickr
                    v-if="initDate"
                    v-model="endDate"
                    class="form-control"
                    :config="{
                    altInput: true,
                    altFormat: 'd-m-Y H:i',
                    enableTime: true,
                    time_24hr: true,
                    wrap : true,
                    plugins: [
                       new yearDropdownPlugin({
                         yearStart: 100,
                         yearEnd: 0
                      })
                     ],
                  }"
                />

                <v-select v-else disabled/>

              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Player" label-for="username">
                <template #label>{{ $t('labels.player') }}</template>
                <v-select
                    @option:selected="selectUser"
                    :options="users"
                    v-model="userSelected"
                    @search="onSearch"
                    :filterable="false"
                    :clearable="true"
                    :loading="loading.search"
                    label="userName"
                >
                  <template slot="no-options">
                    {{ $t("type_a_letter_to_start_the_search") }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.userName }}
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col md="3">

              <b-form-group :label="$t('labels.amount')"
                            label-for="amount">
                <template #label>{{ $t('labels.amount') }} {{$t('greater_than')}}</template>
                <b-input placeholder="0.00"
                         type="number"
                         class="form-control"
                         v-model="amount"></b-input>

              </b-form-group>

            </b-col>

            <b-col md="3">

              <b-form-group
                  label-for="getBets"
              >
                <template #label>{{$t('bets')}}</template>
                <b-form-checkbox
                    id="getBets"
                    v-model="isGetBets"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline
                />
              </b-form-group>

            </b-col>

          </b-row>

          <b-overlay :show="loading.overlay">
            <b-table-simple style="min-height: 300px"
                            hover
                            caption-top
                            small
                            responsive>

              <b-thead head-variant="light">
                <b-tr>
                  <b-th>{{ $t('message.tableHeader.date') }}/{{ $t('hour') }}</b-th>
                  <b-th>{{ $t('labels.name_player') }}</b-th>
                  <b-th>{{ $t('labels.user') }}</b-th>
                  <b-th>{{ $t('labels.amount') }}</b-th>
                  <b-th>{{ $t('labels.provider') }}</b-th>
                  <b-th>{{ $t('labels.brands') }}</b-th>
                  <b-th>{{ $t('game') }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>

                <b-tr
                    :variant="tr._rowVariant"
                    v-for="(tr, indexTr) in data.rewards"
                    :key="indexTr"
                >
                  <b-td class="text-noWrap">{{ formatDate(tr.createdAt) }}</b-td>
                  <b-td class="text-noWrap">{{ tr.user_from.firstName }}</b-td>
                  <b-td class="text-noWrap">{{ tr.user_from.userName }}</b-td>
                  <b-td class="text-noWrap">{{ tr.amount | currency({symbol: ""})  }}</b-td>
                  <b-td class="text-noWrap"> {{ tr.productId }} </b-td>
                  <b-td class="text-noWrap"> {{ tr.brandId }} </b-td>
                  <b-td class="text-noWrap"> {{ tr.gameId }} </b-td>
                </b-tr>
                <b-tr v-if='data.rewards.length < 1'>
                  <b-th colspan="5" class='text-center'>
                    <div class='m-2'>{{ $t('no_data') }}</div>
                  </b-th>
                </b-tr>
              </b-tbody>


            </b-table-simple>
          </b-overlay>

        </b-card>
      </b-col>
    </b-row>
  </div>

</template>

<script>

import {defineComponent, onUnmounted} from "@vue/composition-api";
import {
  BCard,
  BCardTitle,
  BCol, BFormCheckbox,
  BFormGroup,
  BOverlay,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTh,
  BThead, BTr
} from "bootstrap-vue";
import vSelect from "vue-select";
import {mapActions, mapState} from "vuex";
import store from "@/store";
import userStoreModule from "@/views/apps/user/userStoreModule";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";
import ExportButton from "@/views/pages/export/ExportButton.vue";

export default defineComponent({
  name: "BigPrizesReport",
  components: {
    BFormCheckbox,
    ExportButton,
    BTr,
    BTbody,
    BThead,
    BTd,
    BTableSimple,
    BTh,
    BOverlay,
    vSelect,
    BFormGroup,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    flatPickr
  },

  data() {
    return {
      loading: {
        overlay: false,
        search: false
      },

      initDate:  moment().format("YYYY-MM-DD:00:00:00"),
      endDate:  moment().format("YYYY-MM-DD:23:59:59"),

      data: {
        rewards: [],
      },

      amount: 0,

      users: [],
      userSelected: null,
      isGetBets: false
    }
  },

  setup() {
    if (!store.hasModule('app-user'))
      store.registerModule('app-user', userStoreModule);

    onUnmounted(() => {
      if (store.hasModule('app-user')) store.unregisterModule('app-user')
    })
  },

  watch: {
    userSelected: {
      handler() {
        this.getData()
      }
    },
    initDate: {
      handler() {
        if (this.initDate !== '' && this.endDate === '') {
          this.endDate = new Date()
        }

        if (this.initDate === '') {
          this.endDate = ''
        }

        this.getData()
      }
    },
    endDate: {
      handler() {
        this.getData()
      }
    },
    amount: {
      handler() {
      setTimeout(() => {
          this.getData()
        }, 500);
      }
    },
    isGetBets : {
      handler() {
        setTimeout(() => {
          this.getData()
        }, 500);
      }
    }
  },


  mounted() {
    this.getData()
  },

  computed: {
    ...mapState("whitelabelCurrencyNabvar", ["whitelabel", "currency"]),
    exportFilter(){
      return {
        currency: this.currency,
        whitelabel: this.whitelabel._id,
        datein: this.initDate,
        dateout: this.endDate,
        amount: this.amount || 0,
        id: this.userSelected?._id,
        getBets: this.isGetBets ? 'BET' : null
      }
    }
  },

  methods: {
    yearDropdownPlugin,
    ...mapActions("reward", ["getBigPrizes"]),

    onSearch(username) {
      if (username.length) {
        this.loading.search = true

        const whitelabelId = this.$store.state.whitelabelCurrencyNabvar.whitelabel._id;
        const queryParams = { username: username.trim(), whitelabel: whitelabelId };

        if (this.timeOut) clearTimeout(this.timeOut);

        this.timeOut = setTimeout(() => {
          store.dispatch("app-user/fetchUsersByName", queryParams).then((r) => {
            this.users = r.data;
            this.loading.search = false

          }).catch(() => {
            this.loading.search = false
          })
        }, 500);
      }
    },

    getData() {
      this.loading.overlay = true
      
      let filter = {
        currency: this.currency,
        whitelabel: this.whitelabel._id,
        datein: this.initDate,
        dateout: this.endDate,
        amount: this.amount || 0,
        getBets: this.isGetBets ? 'BET' : null
      }

      if (this.userSelected) {
        filter = {id: this.userSelected._id, ...filter}
      }


      this.getBigPrizes(filter).then(r => {
        this.data = r.data
        this.loading.overlay = false

      }).catch(err => {
        console.log(err)
        this.loading.overlay = false
      })
    },

    selectUser(user) {
      this.userSelected = user
    },

    formatDate(value) {
      return value ? moment(value).format('DD/MM/YYYY hh:mm a') : '-'
    }
  }
})


</script>


<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>